import React from 'react'

import { graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Img from 'gatsby-image'

import InnerPage from '../components/InnerPage'

function PostTemplate({ data }) {
    const content = data.markdownRemark
    return (
        <InnerPage title={content.frontmatter.title} description={content.excerpt} image={content.frontmatter.feature_image && content.frontmatter.feature_image.childImageSharp.fluid.src}>
            <div className="container">
                <div className={content.frontmatter.feature_image ? `single-blog-box-layout2` : `single-blog-box-layout3`}>
                    {content.frontmatter.feature_image && (
                        <div className="blog-banner">
                            <Img fluid={content.frontmatter.feature_image.childImageSharp.fluid} />
                        </div>
                    )}
                    <div className="single-blog-content">
                        <div className="blog-entry-content">
                            <ul className="entry-meta meta-color-dark">
                                <li><i className="fas fa-calendar-alt"></i>{content.frontmatter.date}</li>
                            </ul>
                            <h2 className="item-title">{content.frontmatter.title}</h2>
                            <ul className="item-social">
                                <li><OutboundLink href={`https://facebook.com/sharer/sharer.php?u=https://www.jugendgestaltetzukunft.de/posts${content.fields.slug}`} className="facebook"><i className="fab fa-facebook-f"></i>TEILEN</OutboundLink></li>
                                <li><OutboundLink href={`https://twitter.com/intent/tweet/?text=${content.frontmatter.title} - ${content.excerpt};url=https://wwww.jugendgestaltetzukunft.de/posts${content.fields.slug}`} className="twitter"><i className="fab fa-twitter"></i>TEILEN</OutboundLink></li>
                            </ul>
                        </div>
                        <div class="blog-details" dangerouslySetInnerHTML={{ __html: content.html }} />
                    </div>
                </div>
            </div>
        </InnerPage>
    )
}

export default PostTemplate

export const articlePageQuery = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            excerpt(pruneLength: 100, format: PLAIN)
            frontmatter {
                date(formatString: "DD. MMMM YYYY", locale: "de")
                title
                feature_image {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid_noBase64
                            src
                        }
                        
                    }
                }
            }
            fields {
                slug
            }
        }
    }
`